import axios from 'axios'


// 创建 axios 实例
let instance = axios.create({
    baseURL: '/api', // 所有请求的基础路径都会自动加上 /apiv2
    headers: {'Content-Type': 'application/json'},
    timeout: 80000, // 超时时间
    withCredentials: true, // 允许跨域请求时携带 Cookie
})



// 添加响应拦截器
instance.interceptors.response.use(
    (response) => {
        // 只返回 response.data 部分
        return response.data;
    },
    (error) => {
        console.log("当前是报错了哦");

        // if (error.response) {
        //     const { status } = error.response; // 获取状态码
        //
        //     if (status === 400) {
        //         console.log("未登录或登录已过期，跳转到登录页");
        //         // 跳转到登录页
        //         router.push("/admin/login");
        //     }
        // }

        // 如果发生错误，直接抛出
        return Promise.reject(error);
    }
);
export default instance;

