import { createRouter, createWebHistory } from 'vue-router'
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';  // 引入 nprogress 的样式
const router = createRouter({
    // history: createWebHistory(import.meta.env.BASE_URL),
    history: createWebHistory('/'),
    routes: [
        {
            path: '/home',
            name: 'home',
            component: () => import('./user/MainIndex.vue'),
            children:[
                {
                    path:"/UserManage",
                    name:'用户管理',
                    component:() => import('./user/UserManage.vue'),
                },
                {
                    path:"/GoodsStashList",
                    name:'商品仓库',
                    component:() => import('./user/GoodsStashList.vue'),
                },
                {
                    path:"/GoodsStashListV2",
                    name:'测试仓库',
                    component:() => import('./user/GoodsStashListV2.vue'),
                },
                {
                    path:"/HighestWareV2",
                    name:'总仓管理',
                    component:() => import('./user/HighestWareV2.vue'),
                },
                {
                    path:"/MallList",
                    name:'美团店铺',
                    component:() => import('./user/MallList.vue'),
                },
                {
                    path:"/OrderAllList",
                    name:'订单管理',
                    component:() => import('./user/OrderAllList.vue'),
                },
                {
                    path:"/OrderAllListV2",
                    name:'订单管理V2',
                    component:() => import('./user/OrderAllListV2.vue'),
                },
                {
                    path:"/CallGoodsTaskList",
                    name:'上货',
                    component:() => import('./user/CallGoodsTaskList.vue'),
                },
                {
                    path:"/PddIdFission",
                    name:'拼多多ID裂变',
                    component:() => import('./user/PddIdFission.vue'),
                },
                {
                    path:"/PddIdFission2",
                    name:'拼多多ID裂变2',
                    component:() => import('./user/PddIdFission2.vue'),
                },
            ]
        },
        {
            path: '/',
            redirect: '/user/login',
        },
        {
            path: '/user/login',
            name: '用户登录',
            component: () => import('./user/Login.vue'),
        },
        {
            path: '/:pathMatch(.*)*', // 匹配所有未定义路径
            name: 'not-found',
            component: () => import('./Error.vue'), // 你的默认组件（比如 404 页面）
        },

    ],
})

// 全局前置守卫：开始加载条
router.beforeEach((to, from, next) => {
    NProgress.start();
    next();
});

// 全局后置守卫：结束加载条
router.afterEach(() => {
    NProgress.done();
});

export default router
