import {createApp} from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import ArcoVue from '@arco-design/web-vue';
import '@arco-design/web-vue/dist/arco.css';
import HttpUtils from './utils/HttpUtils'
import router from './components/router'
import {dayjs} from 'element-plus'


const app = createApp(App)

/*引入 ArcoUi*/
app.use(ArcoVue);

/*引入自定义Http类*/
app.config.globalProperties.$http = HttpUtils
app.config.globalProperties.$dayjs=dayjs

/*引入路由插件*/
app.use(router);
app.use(ElementPlus, {locale: zhCn})

app.mount('#app')
